import breadcrumbComp from "../../common/breadcrumb-comp.vue";
import Utility from "../../../shared/utility";
import EncryptUtility from "../../utility/js/encrypt-utility";
import AddFavourite from "../../utility/js/addFav";
import ReasonService from "./app-mrb-reason-service";
export default {
  data: () => ({
    max20Rule: [(v) => (v || "").length <= 20 || "Max Length of 20 character", (v) => !!v || "Field is required"],
    name: "MRB- Hold manage",
    mainPage: "",
    subPage: "",
    pageUrl: "",
    popupTitleParent: "",
    backSlash: true,
    selectedProject: "",
    selectedCountry: "",
    favouriteList: [],
    actionList: [],
    showAdd: false,
    projectList: [],
    userId: EncryptUtility.localStorageDecrypt("userID"),
    projectSelected: true,
    refresh: true,
    totalRecords: 0,
    entryReasons: [],
    exitReasons: [],
    editArray: [],
    entryReasonsList: [],
    projReasonsList: [],
    selectedExitReason: "",
    selectedEntryReason: "",
    selectedReason: "",
    projectTable: false,
    showReasonsTbl: true,
    itemStatusDialog: false,
    reasonId: 0,
    addMRBReasonDialog: false,
    itemDeleteDialog: false,
    reasonNew: "",
    selectedReasonType: "",
    addProjList: [],
    showProjReasonTbl: false,
    showReasonType: false,
    direction: "",
    projAddList: [],
    projSubmitList: [],
    saveObject: [],
    deleteObject: [],
    disableReason: false,
    editbit: 0,
    showEntryDelete: false,
    showExitDelete: false,
    projSearchList: [],
    reasonType: [
      {
        name: "Entry",
        value: "Entry",
      },
      {
        name: "Exit",
        value: "Exit",
      },
    ],
    headers: [
      {
        text: "Active",
        align: "start",
        value: "active",
        class: "primary customwhite--text",
      },
      {
        text: "Project",
        value: "Project",
        class: "primary customwhite--text",
      },
    ],
    projectheaders: [
      {
        text: "Active",
        align: "start",
        value: "active",
        class: "primary customwhite--text",
      },
      {
        text: "Project",
        value: "project",
        class: "primary customwhite--text",
      },
    ],
    reasonheaders: [
      {
        text: "Active",
        align: "start",
        value: "active",
        class: "primary customwhite--text",
      },
      {
        text: "Reason",
        value: "reason",
        class: "primary customwhite--text",
      },
      {
        text: "Type",
        value: "direction",
        class: "primary customwhite--text",
      },
    ],
  }),
  async created() {
    this.route = this.$route.path.replace("/", "");
    let data = await AddFavourite.getPageAction(this.userId, this.route);
    this.actionList = data.actionList;
    this.mainPage = data.mainPage;
    this.favouriteList = data.favouriteList;
    this.pageUrl = data.pageUrl;
    this.subPage = data.subPage;
    this.showAdd = data.showAdd;
  },
  mounted() {
    this.getProject();
    this.dropdwnReasonSearch();
  },
  methods: {
    //Project Details
    async getProject() {
      let projectData = await ReasonService.getProject(this.userId);
      this.projectList = projectData.ProjectInfo;
      this.addProjList = projectData.ProjectInfo;
    },
    //Reasons Details
    async dropdwnReasonSearch() {
      this.entryReasons = [];
      this.exitReasons = [];
      let searchObj = {
        UserID: parseInt(this.userId),
        Pass: 1,
      };
      let searchData = await ReasonService.postReasonsSearch("post", searchObj);
      if (searchData.message != "NA") {
        this.entryReasons = searchData[0].reasons_r;
        this.exitReasons = searchData[0].reasons_s;
      } else {
        this.entryReasons = [];
        this.exitReasons = [];
      }
    },
    async getReasonProject() {
      this.entryReasonsList = [];
      this.projReasonsList = [];
      if (this.selectedExitReason == "" && this.selectedEntryReason == "") {
        this.selectedReason = 0;
      } else if (this.selectedExitReason != "") {
        this.selectedReason = this.selectedExitReason;
      } else if (this.selectedEntryReason != "") {
        this.selectedReason = this.selectedEntryReason;
      }
      let searchObj = {
        UserID: parseInt(this.userId),
        Pass: 3,
        reason_id: this.selectedReason,
        proj_id: this.selectedProject == "" ? 0 : this.selectedProject,
      };
      let searchData = await ReasonService.postReasonsSearch("post", searchObj);
      if (searchData.message != "NA") {
        if (this.selectedProject != "") {
          this.showReasonsTbl = true;
          this.projectTable = false;
          this.entryReasonsList = searchData;
          this.totalRecords = searchData.length;
        } else {
          this.showReasonsTbl = false;
          this.projectTable = true;
          this.projReasonsList = searchData[0].project_info;
          this.totalRecords = searchData[0].project_info.length;
          this.editbit = searchData[0].edit_bit[0].edit_bit;
          if (this.editbit == 1 && this.selectedEntryReason != "") {
            this.showEntryDelete = true;
          } else if (this.editbit == 1 && this.selectedExitReason != "") {
            this.showExitDelete = true;
          } else {
            this.showExitDelete = false;
            this.showExitDelete = false;
          }
        }
      } else {
        this.projReasonsList = [];
        this.entryReasonsList = [];
      }
    },
    //Project dropdown change
    onProjectSelect() {
      this.selectedExitReason = "";
      this.selectedEntryReason = "";
      this.getReasonProject();
    },
    //Entry Reason Select
    onEntryReasonSelect() {
      this.selectedProject = "";
      this.selectedExitReason = "";
      this.showExitDelete = false;
      this.getReasonProject();
    },
    //Exit Reason Select
    onExitReasonSelect() {
      this.selectedProject = "";
      this.selectedEntryReason = "";
      this.showEntryDelete = false;
      this.getReasonProject();
    },
    //Reset Function
    resetFunction() {
      this.selectedProject = "";
      this.selectedEntryReason = "";
      this.selectedExitReason = "";
      this.projectTable = false;
      this.projReasonsList = [];
      this.entryReasonsList = [];
      this.totalRecords = 0;
      this.showReasonsTbl = true;
      this.showExitDelete = false;
      this.showEntryDelete = false;
    },
    changeProjectReason(item) {
      this.editArray = [];
      this.itemStatusDialog = true;
      this.editArray = item;
      if (item.active == true) {
        this.popupTitleParent = "Are you sure you want to disable this item?";
      } else if (item.active == false) {
        this.popupTitleParent = "Are you sure you want to enable this item?";
      }
    },
    //status change
    itemStatusChange() {
      this.editReasonProject(this.editArray);
    },
    //Update the status
    editReasonProject(item) {
      let LoaderDialog = {
        visible: true,
        title: "Please Wait...",
      };
      this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
      if (this.selectedEntryReason != "") {
        this.reasonId = this.selectedEntryReason;
      } else if (this.selectedExitReason != "") {
        this.reasonId = this.selectedExitReason;
      } else {
        this.reasonId = item.reason_key;
      }
      let EditObject = {
        user_id: parseInt(this.userId),
        pass: 5,
        reason_id: this.reasonId,
        proj_id: item.project_id === 0 || typeof item.project_id === "undefined" ? this.selectedProject : item.project_id,
        Active: !item.active == true ? 1 : 0,
      };
      this.axios
        .post("/cp/mrb_hold_manage", EditObject)
        .then((response) => {
          if (response.status == 200 || response.status == 204) {
            this.itemStatusDialog = false;
            let Alert = {
              type: "success",
              isShow: true,
              message: response.data.body.message,
            };
            this.getReasonProject();
            this.$store.commit("ConfigModule/Alert", Alert);
            LoaderDialog.visible = false;
            this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
          } else {
            LoaderDialog.visible = false;
            this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
            let Alert = {
              type: "error",
              isShow: true,
              message: response.data.message,
            };
            this.$store.commit("ConfigModule/Alert", Alert);
          }
          LoaderDialog.visible = false;
          this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
        })
        .catch((error) => {
          LoaderDialog.visible = false;
          this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
          let Alert = {
            type: "error",
            isShow: true,
            message: Utility.apiError(error),
          };
          this.$store.commit("ConfigModule/Alert", Alert);
          this.errorMessage = error.message;
        });
    },
    //New MRB Reason Button Click Event
    newAddClicked() {
      this.getProject();
      this.addMRBReasonDialog = true;
    },
    //reason dropodwn select
    reasonTypeSelect() {
      this.showProjReasonTbl = true;
    },
    //Validation of role
    async roleValidation() {
      let LoaderDialog = {
        visible: true,
        title: "Please Wait...",
      };
      this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
      let searchObj = {
        UserID: parseInt(this.userId),
        Pass: 7,
        reason: this.reasonNew,
      };
      this.axios
        .post("/cp/mrb_hold_manage", searchObj)
        .then((response) => {
          if (response.status == 200 || response.status == 204) {
            this.showReasonType = true;
            this.disableReason = true;
            LoaderDialog.visible = false;
            this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
          } else {
            LoaderDialog.visible = false;
            this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
            let Alert = {
              type: "error",
              isShow: true,
              message: response.data.message,
            };
            this.$store.commit("ConfigModule/Alert", Alert);
          }
          LoaderDialog.visible = false;
          this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
        })
        .catch((error) => {
          LoaderDialog.visible = false;
          this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
          let Alert = {
            type: "error",
            isShow: true,
            message: Utility.apiError(error),
          };
          this.$store.commit("ConfigModule/Alert", Alert);
          this.errorMessage = error.message;
        });
    },
    //New MRB change event
    changeProject(item) {
      if (this.selectedReasonType == "Entry") {
        this.direction = "R";
      } else {
        this.direction = "S";
      }
      if (item && item.active) {
        this.projAddList = {
          active: item.active,
          proj_id: item.ProjKey,
        };
        this.projSubmitList.push(this.projAddList);
      }
      this.saveObject = {
        pass: 8,
        json: JSON.stringify({
          new_reason: {
            reason: this.reasonNew,
            direction: this.direction,
            user_id: parseInt(this.userId),
            project: this.projSubmitList,
          },
        }),
      };
    },
    //Add new reason Button
    addMRBReason() {
      if (this.$refs.addMRBForm.validate()) {
        let LoaderDialog = {
          visible: true,
          title: "Please Wait...",
        };
        this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
        this.axios
          .post("/cp/mrb_hold_manage", this.saveObject)
          .then((response) => {
            if (response.status == 200 || response.status == 204) {
              let Alert = {
                type: "success",
                isShow: true,
                message: response.data.body.message,
              };
              this.projSubmitList = [];
              this.reasonNew = "";
              this.selectedReasonType = "";
              this.disableReason = false;
              this.showReasonType = false;
              this.showProjReasonTbl = false;
              this.closeDialog();
              this.dropdwnReasonSearch();
              this.$store.commit("ConfigModule/Alert", Alert);
              LoaderDialog.visible = false;
              this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
            } else {
              LoaderDialog.visible = false;
              this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
              let Alert = {
                type: "error",
                isShow: true,
                message: response.data.message,
              };
              this.$store.commit("ConfigModule/Alert", Alert);
            }
            LoaderDialog.visible = false;
            this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
          })
          .catch((error) => {
            LoaderDialog.visible = false;
            this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
            let Alert = {
              type: "error",
              isShow: true,
              message: Utility.apiError(error),
            };
            this.$store.commit("ConfigModule/Alert", Alert);
            this.errorMessage = error.message;
          });
      }
    },
    //Close the popup
    closeDialog() {
      if (this.addMRBReasonDialog) {
        this.reasonNew = "";
        this.selectedReasonType = "";
        this.showProjReasonTbl = false;
        this.addMRBReasonDialog = false;
        this.addProjList = [];
        this.disableReason = false;
        this.showReasonType = false;
        this.$refs.addMRBForm.resetValidation();
      } else if (this.itemStatusDialog) {
        this.itemStatusDialog = false;
       // this.getReasonProjectNoLoader();
      }
    },
    //Delete the reason
    deleteReason() {
      let LoaderDialog = {
        visible: true,
        title: "Please Wait...",
      };
      this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
      if (this.selectedEntryReason != "") {
        this.reasonId = this.selectedEntryReason;
      }
      if (this.selectedExitReason != "") {
        this.reasonId = this.selectedExitReason;
      }
      this.deleteObject = {
        pass: 10,
        user_id: parseInt(this.userId),
        reason_id: this.reasonId,
      };
      this.axios
        .post("/cp/mrb_hold_manage", this.deleteObject)
        .then((response) => {
          if (response.status == 200 || response.status == 204) {
            this.itemDeleteDialog = false;
            let Alert = {
              type: "success",
              isShow: true,
              message: response.data.body.message,
            };
            this.selectedProject = "";
            this.selectedEntryReason = "";
            this.selectedExitReason = "";
            this.projectTable = false;
            this.projReasonsList = [];
            this.entryReasonsList = [];
            this.totalRecords = 0;
            this.showReasonsTbl = true;
            this.showEntryDelete = false;
            this.showExitDelete = false;
            this.dropdwnReasonSearch();
            this.$store.commit("ConfigModule/Alert", Alert);
            LoaderDialog.visible = false;
            this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
          } else {
            LoaderDialog.visible = false;
            this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
            let Alert = {
              type: "error",
              isShow: true,
              message: response.data.message,
            };
            this.$store.commit("ConfigModule/Alert", Alert);
          }
          LoaderDialog.visible = false;
          this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
        })
        .catch((error) => {
          LoaderDialog.visible = false;
          this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
          let Alert = {
            type: "error",
            isShow: true,
            message: Utility.apiError(error),
          };
          this.$store.commit("ConfigModule/Alert", Alert);
          this.errorMessage = error.message;
        });
    },
    //Getting data without a loader
    async getReasonProjectNoLoader() {
      let searchObj = {
        UserID: parseInt(this.userId),
        Pass: 3,
        reason_id: this.selectedReason,
        proj_id: this.selectedProject == "" ? 0 : this.selectedProject,
      };
      let searchData = await ReasonService.postReasonsSearchNoLoader("post", searchObj);
      if (searchData.message != "NA") {
        if (this.selectedProject != "") {
          this.entryReasonsList = searchData;
        } else {
          this.projReasonsList = searchData[0].project_info;
        }
      } else {
        this.projReasonsList = [];
        this.entryReasonsList = [];
      }
    },
    //Get Page Action and name
    //On Click of Favorite icon
    addFav() {
      this.showAdd = !this.showAdd;
      AddFavourite.add_remove_Favourite(this.showAdd, this.userId, this.route);
    },
    //To set the action
    actionSetter(action) {
      if (this.actionList !== null && this.actionList.length > 0) {
        let filter = this.actionList.filter((entry) => entry.SystemName === action);
        return filter !== undefined && filter !== null && filter.length == 1 ? true : false;
      } else return false;
    },
  },
  components: {
    breadcrumbComp,
  },
};
